import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, Container, Button, FormCheck } from 'react-bootstrap';
import * as Constants from '../../constants/constants'

export default function ChargesList(props) {
    const {from} = props
    const labors = props.list ? props.list : []
    const notes = props.notes ? props.notes : []
    const credits = props.credits ? props.credits : []
    const parts = props.parts ? props.parts : []
    const totals = props.totals ? props.totals : []
    const sublets = props.sublets ? props.sublets : []
    const waste = props.waste ? props.waste : []
    const payments = props.payments ? props.payments : []
    const toggleCost = props.toggleCost ? props.toggleCost : false
    const profits = props.profits ? props.profits : null
    const [checkedItems, setCheckedItems] = useState([])
    const [selectedItems, setSelectedItems] = useState([]); // Changed from selectedItem to selectedItems
    const rowRefs = useRef({});

    const selectItem = (field, item) => {
        // Check if the item is already selected
        const isSelected = selectedItems.some((selectedItem) => selectedItem._id === item._id);

        if (isSelected) {
            // If selected, remove it from the selectedItems array
            setSelectedItems(selectedItems.filter((selectedItem) => selectedItem._id !== item._id));
        } else {
            // If not selected, add it to the selectedItems array
            setSelectedItems([...selectedItems, {...item, field: field}]);
        }
    };

    async function submitAddItems() {

        const res = await props.addSelectedItemsToInvoice(selectedItems)
        if(res)
        {
            console.log("success-", selectedItems.length, '-items updated');
        }
        setSelectedItems([])
    }

    useEffect(() => {
        // Reset background colors for all items
        Object.values(rowRefs.current).forEach((ref) => {
            if (ref) {
                ref.style.backgroundColor = '';
            }
        });

        // Highlight the selected items
        selectedItems.forEach((selectedItem) => {
            const selectedRef = rowRefs.current[selectedItem._id];
            if (selectedRef) {
                selectedRef.style.backgroundColor = '#28a745';
            }
        });
    }, [selectedItems]);

    return (
        labors.length > 0 || notes.length > 0 || parts.length > 0 || credits.length > 0 || sublets.length > 0 || waste.length > 0 ?
            <React.Fragment>
                {selectedItems.length > 0 ?
                    <>
                    <Button style={{position:"absolute", right:"54px", top:"-54px"}} variant='success' onClick={()=>submitAddItems()}>Add Items</Button>
                    </>

                    : null}
                {toggleCost ? <Row noGutters>
                    <Col></Col>
                    <Col>Cost: {profits.Cost.toFixed(2)}</Col>
                    <Col>Price: {profits.Price.toFixed(2)} </Col>
                    <Col> Profit:  {profits.Profit.toFixed(2)}</Col>
                    <Col> Margin:  {profits.Margin.toFixed(2)}%</Col>
                </Row> : null}
                <Container style={{ minHeight: "100%", minWidth: "100%", borderStyle: "outset", position: "relative" }} >
                    <Row >
                        <Col>{/* FILLER COL */}</Col>
                        <Col xs lg="1" style={{ textAlign: "right" }}>Qty</Col>
                        {toggleCost ?
                            <React.Fragment>
                                <Col xs lg="1" style={{ textAlign: "right" }}>Cost</Col>
                                <Col xs lg="1" style={{ textAlign: "right" }}>Ext.</Col>
                            </React.Fragment> : null}

                        <Col xs lg="1" style={{ textAlign: "right" }}>Rate</Col>
                        <Col xs lg="1" style={{ textAlign: "right" }}> Total </Col>
                    </Row>
                    {parts.length > 0 ?
                        <div>
                            <Row>
                                <Col style={{ fontSize: "20px", marginLeft: "-10px" }}>Parts
                                    {from === 'invoice' ?
                                        <Button style={{ width: "60px", padding: "0px", marginLeft: "10px" }} size="sm" variant="secondary" onClick={props.reorderItems.bind(this, "parts")}>Reorder</Button>
                                        : null}

                                </Col>

                            </Row>
                            {parts.map((item, key) =>
                                <Row key={key}
                                    className="jobcard"
                                    ref={(el) => (rowRefs.current[item._id] = el)}
                                    onClick={!props.disabled ? props.onClick.bind(this, { item: item, type: "Parts" }) : () => selectItem('parts', item)}>
                                    {from === 'invoice' ?
                                        <Col style={{ maxWidth: "fit-content", textAlign: "left" }}>
                                            <FormCheck onClick={(e) => {
                                                e.stopPropagation()
                                                e.target.checked ? setCheckedItems((prevArray) => [...prevArray, item]) : setCheckedItems(checkedItems.filter(i => i._id !== item._id))
                                            }} />
                                        </Col> : null}
                                    <Col xs lg="2">{`${item.partNumber}${item.distributor ? `-(${item.distributor})` : ''}`}</Col>
                                    <Col > {item.description}  </Col>
                                    <Col xs lg="1" style={{ textAlign: "right" }}> {item.quantity ? parseFloat(item.quantity).toFixed(2) : 0.00.toFixed(2)} </Col>
                                    {toggleCost ?
                                        <React.Fragment>
                                            <Col xs lg="1" style={{ textAlign: "right" }}> {item.cost ? parseFloat(item.cost).toFixed(2) : 0.00.toFixed(2)} </Col>
                                            <Col xs lg="1" style={{ textAlign: "right" }}> {item.cost && item.quantity ? (item.cost * item.quantity).toFixed(2) : 0.00.toFixed(2)} </Col>
                                        </React.Fragment> : null}
                                    <Col xs lg="1" style={{ textAlign: "right" }}> {item.list ? parseFloat(item.list).toFixed(2) : 0.00.toFixed(2)} </Col>
                                    <Col xs lg="1" style={{ textAlign: "right" }}> {item.total ? parseFloat(item.total).toFixed(2) : 0.00.toFixed(2)}

                                    </Col>


                                </Row>
                            )}

                            <br />
                        </div>
                        : null}

                    {labors.length > 0 ?
                        <div>
                            <Row>
                                <Col style={{ fontSize: "20px", marginLeft: "-10px" }}>
                                    Labor{/*  */}
                                    {from === 'invoice' ?
                                        <Button style={{ width: "60px", padding: "0px", marginLeft: "10px" }} size="sm" variant="secondary" onClick={props.reorderItems.bind(this, "labor")}>Reorder</Button>
                                        : null}
                                </Col>
                            </Row>
                            {labors.map((item, key) =>
                                <Row key={key} className="jobcard" onClick={!props.disabled ? props.onClick.bind(this, { item: item, type: "Labor" }) : from === 'search' ? () => selectItem('labor', item) : null}
                                    ref={(el) => (rowRefs.current[item._id] = el)} >
                                    {from === 'invoice' ?
                                        <Col style={{ maxWidth: "fit-content", textAlign: "left" }}>
                                            <FormCheck onClick={(e) => {
                                                e.stopPropagation()
                                                e.target.checked ? setCheckedItems((prevArray) => [...prevArray, item]) : setCheckedItems(checkedItems.filter(i => i._id !== item._id))
                                            }} />
                                        </Col> : null}
                                    <Col>{("00" + item.employeeID).substr(item.employeeID.length - 1)} - {item.description}</Col>
                                    <Col xs lg="1" style={{ textAlign: "right" }}> {item.amount ? parseFloat(item.amount).toFixed(2) : 0.00.toFixed(2)}  </Col>
                                    {toggleCost ?
                                        <>
                                            <Col xs lg="1" style={{ textAlign: "right" }}> {'--'}  </Col>
                                            <Col xs lg="1" style={{ textAlign: "right" }}> {'--'}  </Col>
                                        </> : null}
                                    <Col xs lg="1" style={{ textAlign: "right" }}> {item.rate ? parseFloat(item.rate).toFixed(2) : 0.00.toFixed(2)} </Col>
                                    <Col xs lg="1" style={{ textAlign: "right" }}>
                                        {item.total ? parseFloat(item.total).toFixed(2) : 0.00.toFixed(2)}
                                    </Col>
                                </Row>
                            )}
                            <br />
                        </div>
                        : null}

                    {sublets.length > 0 ?
                        <div>
                            <Row>
                                <Col style={{ fontSize: "20px", marginLeft: "-10px" }}>Sublets</Col>
                            </Row>
                            {sublets.map((item, key) =>
                                <Row /* style={{ background: background.sublets[key] }} */ key={key} className="jobcard" onClick={!props.disabled ? props.onClick.bind(this, { item: item, type: "Sublets" }) : null}>
                                    {from === 'invoice' ?
                                        <Col style={{ maxWidth: "fit-content", textAlign: "left" }}>
                                            <FormCheck onClick={(e) => {
                                                e.stopPropagation()
                                                e.target.checked ? setCheckedItems((prevArray) => [...prevArray, item]) : setCheckedItems(checkedItems.filter(i => i._id !== item._id))
                                            }} />
                                        </Col> : null}
                                    <Col xs lg="2">{item.vendor}</Col>
                                    <Col >{item.description} </Col>
                                    {toggleCost ? <React.Fragment>
                                        <Col xs="1" style={{ textAlign: "right" }}>
                                            {item.list ? parseFloat(item.cost).toFixed(2) : 0.00.toFixed(2)}
                                        </Col>
                                        <Col xs="1" style={{ textAlign: "right" }}>
                                            {item.list ? parseFloat(item.cost).toFixed(2) : 0.00.toFixed(2)}
                                        </Col>
                                        <Col xs="1" >
                                        </Col>
                                    </React.Fragment> : null}
                                    <Col xs="1" style={{ textAlign: "right" }}>
                                        {item.list ? parseFloat(item.list).toFixed(2) : 0.00.toFixed(2)}
                                    </Col>

                                </Row>
                            )}
                            <br />
                        </div>
                        : null}

                    {waste.length > 0 ?
                        <div>
                            <Row>
                                <Col style={{ fontSize: "20px", marginLeft: "-10px" }}>Waste</Col>
                            </Row>
                            {waste.map((item, key) =>
                                <Row /* style={{ background: background.waste[key] }} */ key={key} className="jobcard" onClick={!props.disabled ? props.onClick.bind(this, { item: item, type: "Waste" }) : null}>
                                    {from === 'invoice' ?
                                        <Col style={{ maxWidth: "fit-content", textAlign: "left" }}>
                                            <FormCheck onClick={(e) => {
                                                e.stopPropagation()
                                                e.target.checked ? setCheckedItems((prevArray) => [...prevArray, item]) : setCheckedItems(checkedItems.filter(i => i._id !== item._id))
                                            }} />
                                        </Col> : null}
                                    <Col xs lg="2">{item.vendor}</Col>
                                    <Col >{item.description} </Col>
                                    {toggleCost ? <React.Fragment>
                                        <Col xs="1" style={{ textAlign: "right" }}>
                                            {item.list ? parseFloat(item.cost).toFixed(2) : 0.00.toFixed(2)}
                                        </Col>
                                        <Col xs="1" style={{ textAlign: "right" }}>
                                            {item.list ? parseFloat(item.cost).toFixed(2) : 0.00.toFixed(2)}
                                        </Col>
                                        <Col xs="1" >
                                        </Col>
                                    </React.Fragment> : null}
                                    <Col xs lg="1" style={{ textAlign: "right" }}>
                                        {item.list ? parseFloat(item.list).toFixed(2) : 0.00.toFixed(2)}
                                    </Col>
                                </Row>
                            )}
                            <br />
                        </div>
                        : null}

                    {credits.length > 0 ?
                        <div>
                            <Row>
                                <Col style={{ fontSize: "20px", marginLeft: "-10px" }}>Credits</Col>
                            </Row>
                            {credits.map((item, key) =>
                                <Row /* style={{ background: background.credits[key] }} */ key={key} className="jobcard" onClick={!props.disabled ? props.onClick.bind(this, { item: item, type: "Credits" }) : null}>
                                    <Col>{item.description}</Col>
                                    <Col xs lg="2">   </Col>
                                    <Col xs lg="2">  </Col>
                                    <Col xs lg="1" style={{ textAlign: "right" }}>{item.amount ? parseFloat(item.amount).toFixed(2) : 0.00.toFixed}

                                    </Col>
                                </Row>
                            )}
                            <br />
                        </div>
                        : null}

                    {notes.length > 0 ?
                        <div>
                            <Row>
                                <Col style={{ fontSize: "20px", marginLeft: "-10px" }}>Notes</Col>
                            </Row>
                            {notes.map((item, key) =>
                                <Row key={key} className="jobcard" onClick={!props.disabled ? props.onClick.bind(this, { item: item, type: "Notes" }) : () => selectItem('notes', item)}
                                ref={(el) => (rowRefs.current[item._id] = el)}>
                                    <Col>{item.description}</Col>

                                    <Col xs lg="2"> </Col>
                                </Row>
                            )}
                            <br />
                        </div>
                        : null}

                    {payments.length > 0 ?
                        <div>
                            <Row>
                                <Col style={{ fontSize: "20px", marginLeft: "-10px" }}>____Payments/Charges____</Col>
                            </Row>
                            {
                                payments.map((item, key) =>
                                ( /* console.log("mapping payments, item: ", item), */
                                    <Row key={key} className="paymentcard" >
                                        <Col xs lg="2"></Col>
                                        <Col></Col>
                                        <Col xs="4" style={{ textAlign: "right" }}> {Constants.dateTimeFormatter(item.Date)}  </Col>
                                        <Col xs="1" style={{ textAlign: "right" }}> {item.Type} </Col>
                                        <Col xs="2" style={{ textAlign: "right" }}>  {item.Amount ? item.Amount.toFixed(2) : '--'} </Col>
                                    </Row>)
                                )
                            }
                            {totals.Balance > 0 || totals.Balance < 0 ?
                                <Row >
                                    <Col></Col>
                                    <Col xs lg="2"></Col>
                                    <Col xs lg="2" style={{ textAlign: "right" }}>Tax: {totals.WorkorderTax ? totals.WorkorderTax.toFixed(2) : 0.00.toFixed(2)}</Col>
                                    <Col xs lg="1" style={{ textAlign: "right" }}> {totals.Balance ? totals.Balance.toFixed(2) : 0.00.toFixed(2)} </Col>
                                </Row>
                                : null}
                        </div>
                        :
                        (
                            <Row >
                                <Col></Col>
                                <Col xs lg="2"></Col>
                                <Col xs lg="2" style={{ textAlign: "right" }}>Tax: {totals.WorkorderTax ? totals.WorkorderTax.toFixed(2) : 0.00.toFixed(2)}</Col>
                                <Col xs lg="1" style={{ textAlign: "right" }}>{totals.Balance ? totals.Balance.toFixed(2) : 0.00.toFixed(2)} </Col>
                            </Row>
                        )
                    }

                    {checkedItems.length > 0 ?
                        <Row style={{ background: "gold" }}>
                            <Col></Col>
                            <Col xs lg="3">Selected Items ({checkedItems.length})</Col>
                            <Col xs lg="2" style={{ textAlign: "right" }}>Tax: {(checkedItems.reduce((acc, obj) => acc + (obj.total !== undefined ? obj.total : obj.list), 0) * .08625).toFixed(2)}</Col>
                            <Col xs lg="1" style={{ textAlign: "right" }}>{(checkedItems.reduce((acc, obj) => acc + (obj.total !== undefined ? obj.total : obj.list), 0) + (checkedItems.reduce((acc, obj) => acc + (obj.total !== undefined ? obj.total : obj.list), 0) * .08625)).toFixed(2)} </Col>
                        </Row>

                        : null}


                </Container>
            </React.Fragment>
            :
            <Container style={{ borderStyle: "outset", minWidth: "100%", minHeight: "100%" }} >

            </Container>
    )
}
