import React from 'react';
import { Modal, } from 'react-bootstrap';
import SearchScreenContents from "./../search_screen/SearchScreen"
export default function SearchScreen(props) {
    const { show, info, from, selectVehicleFromSearch, addSelectedItemsToInvoice } = props

    return (
        <Modal
            show={show}
            onHide={props.close}
            centered
            dialogClassName="search_modal_content"
        >
            
                <SearchScreenContents from={from} info={info} selectVehicleFromSearch={selectVehicleFromSearch} addSelectedItemsToInvoice={addSelectedItemsToInvoice} />
           
        </Modal>
    );

}